// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
  padding-top: 35px;
}

.logo {
  color: #da3540;
  font-family: "TT Hoves ExtraBold";
  font-size: 30px;
  /* padding-top: 30px; */
  padding-left: 40px;
}
.go-project{
padding-right: 40px;

}
.go-project p {
  color: #da3540;
  font-family: "TT Hoves Medium";
  border: 1px solid #da3540;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
}

`, "",{"version":3,"sources":["webpack://./src/Compontents/ReuseableCompontents/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,iCAAiC;EACjC,eAAe;EACf,uBAAuB;EACvB,kBAAkB;AACpB;AACA;AACA,mBAAmB;;AAEnB;AACA;EACE,cAAc;EACd,8BAA8B;EAC9B,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".header-container {\n  padding-top: 35px;\n}\n\n.logo {\n  color: #da3540;\n  font-family: \"TT Hoves ExtraBold\";\n  font-size: 30px;\n  /* padding-top: 30px; */\n  padding-left: 40px;\n}\n.go-project{\npadding-right: 40px;\n\n}\n.go-project p {\n  color: #da3540;\n  font-family: \"TT Hoves Medium\";\n  border: 1px solid #da3540;\n  padding: 15px;\n  border-radius: 10px;\n  cursor: pointer;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
