import logo from './logo.svg';
import './App.css';
import Home from './Compontents/Pages/Home';
import WhatsappLogo from './Compontents/ReuseableCompontents/WhatsappLogo/WhatsappLogo';

function App() {
  return (
    <div className="App">
      <Home />
      <WhatsappLogo />
    </div>
  );
}

export default App;
